/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import "./Layout.scss"

import * as React from "react"

import { Footer } from "../../components/Footer/Footer"
import NavBar from "../../components/NavBar/NavBar"

const Layout = ({ children }) => {
  return (
    <div class="flex min-h-screen flex-col justify-between bg-neutral-50 text-neutral-900">
      <div>
      <div className="bg-navy">
      <NavBar />
      </div>

        {children}
        <Footer />
      </div>
    </div>

  )
}


export default Layout
